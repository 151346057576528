import dateFormat from 'dateformat';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Avatar, Icon, ListItem, SpeedDial, Text } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../../assets/colors';
import { auth, db } from '../../firebase';

function Events({ navigation }) {
    const [nextDays, setNextDays] = useState([]);
    const [allSubEvents, setAllSubEvents] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [updateScreen, setUpdateScreen] = useState(0);
    const [open, setOpen] = useState(false);
    const maxDays = 14;

    const user = auth.currentUser;

    useEffect(() => {
        updateNextDays();
        const unsub = db
            .collection('events')
            .where('participant', 'array-contains', user.uid)
            .onSnapshot((snapshot) =>
                setAllSubEvents(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                )
            );

        return unsub;
    }, []);

    function getDayName(day) {
        let dayName;

        switch (day) {
            case 'Today':
                dayName = 'Heute';
                break;
            case 'Tomorrow':
                dayName = 'Morgen';
                break;
            case 'Monday':
                dayName = 'Montag';
                break;
            case 'Tuesday':
                dayName = 'Dienstag';
                break;
            case 'Wednesday':
                dayName = 'Mittwoch';
                break;
            case 'Thursday':
                dayName = 'Donnerstag';
                break;
            case 'Friday':
                dayName = 'Freitag';
                break;
            case 'Saturday':
                dayName = 'Samstag';
                break;
            case 'Sunday':
                dayName = 'Sonntag';
                break;

            default:
                'Fehler!';
                break;
        }

        return dayName;
    }

    function timestamptoDate(timestamp) {
        var today = new Date();
        var date = new Date();
        if (timestamp) {
            date = timestamp.toDate();
        }

        var formatDate = dateFormat(date, 'dd.mm.yyyy');

        return formatDate;
    }

    function updateNextDays() {
        var tempDayArray = [];
        for (var i = 0; i < maxDays; i++) {
            var date = new Date();
            date.setDate(date.getDate() + i);
            expanded[i] = true;
            tempDayArray.push(date);
        }

        setNextDays(tempDayArray);
    }

    return (
        <SafeAreaView style={styles.container} edges={['left', 'right', 'top']}>
            <ScrollView>
                <Text
                    h2
                    h2Style={{
                        fontWeight: '600',
                        marginVertical: SPACING.MARGIN,
                        paddingLeft: SPACING.MARGIN / 2,
                    }}
                >
                    Upcoming
                </Text>
                {nextDays.map((day, index) => (
                    <View key={index}>
                        <ListItem.Accordion
                            content={
                                <>
                                    <Icon
                                        name="event"
                                        size={30}
                                        color="darkgrey"
                                        style={{
                                            marginRight: SPACING.MARGIN / 2,
                                        }}
                                    />
                                    <ListItem.Content>
                                        <ListItem.Title
                                            style={{
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {getDayName(
                                                dateFormat(day, 'DDDD')
                                            )}
                                            <Text style={{ fontWeight: '200' }}>
                                                {dateFormat(day, ' (dd.mm.)')}
                                            </Text>
                                        </ListItem.Title>
                                    </ListItem.Content>
                                </>
                            }
                            key={index}
                            isExpanded={expanded[index]}
                            onPress={() => {
                                var tempBool = !expanded[index];
                                expanded[index] = tempBool;
                                setUpdateScreen(updateScreen + 1);
                            }}
                            containerStyle={{
                                backgroundColor: 'white',
                            }}
                            topDivider
                        >
                            {allSubEvents
                                .filter(
                                    (event) =>
                                        timestamptoDate(event.data.startDay) ===
                                        dateFormat(day, 'dd.mm.yyyy')
                                )
                                .map((event, itemIndex) => (
                                    <ListItem
                                        containerStyle={{
                                            backgroundColor: 'whitesmoke',
                                            paddingVertical: SPACING.MARGIN / 2,
                                        }}
                                        key={itemIndex}
                                        topDivider
                                    >
                                        <Avatar
                                            containerStyle={{
                                                backgroundColor: 'grey',
                                                borderRadius:
                                                    SPACING.MARGIN / 4,
                                                overflow: 'hidden',
                                            }}
                                            title={event.data.title}
                                            source={{
                                                uri: event.data.headerPhotoURL,
                                            }}
                                            size={72}
                                        />
                                        <ListItem.Content>
                                            <ListItem.Title>
                                                {event.data.title}
                                            </ListItem.Title>
                                            <ListItem.Subtitle>
                                                {event.data.description}
                                            </ListItem.Subtitle>
                                        </ListItem.Content>
                                        <ListItem.Chevron />
                                    </ListItem>
                                ))}
                            {allSubEvents.filter(
                                (event) =>
                                    timestamptoDate(event.data.startDay) ===
                                    dateFormat(day, 'dd.mm.yyyy')
                            ).length === 0 ? (
                                <ListItem
                                    containerStyle={{
                                        width: '100%',
                                        backgroundColor: 'whitesmoke',
                                        justifyContent: 'center',
                                        paddingVertical: SPACING.MARGIN,
                                    }}
                                >
                                    <Icon
                                        name="do-not-disturb-on"
                                        color="grey"
                                        size={20}
                                    />
                                    <ListItem.Title
                                        style={{ fontWeight: '300' }}
                                    >
                                        Keine Einträge vorhanden
                                    </ListItem.Title>
                                </ListItem>
                            ) : (
                                <></>
                            )}
                        </ListItem.Accordion>
                    </View>
                ))}
            </ScrollView>
            <SpeedDial
                isOpen={open}
                icon={{ name: 'add', color: '#fff' }}
                openIcon={{ name: 'close', color: '#fff' }}
                onOpen={() => setOpen(!open)}
                onClose={() => setOpen(!open)}
                color={Colors.primarydark}
            >
                <SpeedDial.Action
                    icon={{ name: 'event', color: Colors.background }}
                    title="Event"
                    color={Colors.backgroundDark}
                />
                <SpeedDial.Action
                    icon={{ name: 'moped', color: Colors.background }}
                    title="Rideout"
                    color={Colors.backgroundDark}
                />
            </SpeedDial>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'whitesmoke',
    },
});

export default Events;
