import React, { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native';
import { Text } from 'react-native';
import { Icon, Image } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../assets/colors';
import { auth } from '../firebase';

function Loading({ navigation }) {
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Loading',
            headerShown: false,
            headerStyle: {
                backgroundColor: Colors.primaryDark,
            },
        });
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                navigation.replace('homeIndex');
            } else {
                navigation.replace('loginList');
            }
        });

        return unsubscribe;
    }, []);

    return (
        <SafeAreaView
            style={{ flex: 1, backgroundColor: Colors.backgroundDark }}
            edges={['left', 'right']}
        >
            <View
                style={{
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Image
                    source={require('../assets/logo.png')}
                    style={{
                        width: SPACING.MARGIN * 12,
                        height: SPACING.MARGIN * 4,
                        marginBottom: SPACING.MARGIN,
                        resizeMode: 'contain',
                    }}
                />
                <Text
                    style={{
                        paddingBottom: SPACING.MARGIN,
                        fontWeight: '200',
                        color: Colors.background,
                    }}
                >
                    Willkommen Bei Somot
                </Text>
                <ActivityIndicator size={'large'} />
            </View>
        </SafeAreaView>
    );
}

export default Loading;
