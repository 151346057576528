import React, { useLayoutEffect } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from './home';
import { Icon, Text } from 'react-native-elements';
import { Colors } from '../../assets/colors';
import { NavigationContainer } from '@react-navigation/native';
import { useEffect } from 'react';
import { auth } from '../../firebase';
import Settings from './settings';
import { SafeAreaView } from 'react-native-safe-area-context';
import ProfileIndex from './profileIndex';
import CustomTabBar from '../components/tabBar';
import Find from './find';
import Events from './events';

function HomeIndex({ navigation }) {
    const Tab = createBottomTabNavigator();

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Home',
            headerShown: false,
        });
    }, []);

    useEffect(() => {
        const unsub = auth.onAuthStateChanged((user) => {
            if (!user) {
                navigation.replace('loginList');
            }
        });
        return unsub;
    }, []);

    return (
        <NavigationContainer independent={true}>
            <Tab.Navigator
                initialRouteName={'home'}
                tabBar={(props) => <CustomTabBar {...props} />}
            >
                <Tab.Screen
                    name="home"
                    component={Home}
                    options={{
                        title: 'Home',
                    }}
                />
                <Tab.Screen
                    name="events"
                    component={Events}
                    options={{ title: 'Events' }}
                />

                <Tab.Screen
                    name="find"
                    component={Find}
                    options={{ title: 'Finden' }}
                />
                <Tab.Screen
                    name="profile"
                    component={ProfileIndex}
                    options={{ title: 'Profil' }}
                />
            </Tab.Navigator>
        </NavigationContainer>
    );
}

export default HomeIndex;
