import React from 'react';
import { useLayoutEffect } from 'react';
import { ScrollView } from 'react-native';
import { Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../../assets/colors';
import { auth } from '../../firebase';

function Home({ navigation }) {
    const user = auth.currentUser.isAnonymous
        ? { email: 'Gast', displayName: 'Gast' }
        : auth.currentUser;

    function signOut() {
        auth.signOut();
    }

    return (
        <SafeAreaView
            style={{ flex: 1, backgroundColor: Colors.background }}
            edges={['left', 'right', 'top']}
        >
            <ScrollView
                contentContainerStyle={{ paddingBottom: SPACING.MARGIN }}
            >
                <Text>Hallo {user.email}</Text>
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <View
                    style={{
                        height: SPACING.MARGIN * 3,
                        width: '100%',
                        backgroundColor: Colors.accent,
                        marginBottom: SPACING.MARGIN,
                    }}
                />
                <Button title="Abmelden" onPress={signOut} />
            </ScrollView>
        </SafeAreaView>
    );
}

export default Home;
