import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { ScrollView, StyleSheet, TouchableHighlight, View } from 'react-native';
import {
    Avatar,
    Button,
    Icon,
    ListItem,
    SearchBar,
    Tab,
    TabView,
    Text,
} from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../../assets/colors';
import { db, auth } from '../../firebase';

function Find({ navigation }) {
    const [tabIndex, setTabIndex] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [searchResUser, setSearchResUser] = useState([]);
    const [searchResEvent, setSearchResEvent] = useState([]);
    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const [isLoadingEvent, setIsLoadingEvent] = useState(false);

    const user = auth.currentUser;

    const msgNoResult = 'Keine Ergebnisse';
    const msgWOSearch = 'Bitte gib etwas zum suchen ein!';

    function getSearchedUser() {
        setIsLoadingUser(true);
        if (searchText.length > 0) {
            db.collection('userDB')
                .get()
                .then((snapshot) => {
                    setSearchResUser(
                        snapshot.docs
                            .filter(
                                (item) =>
                                    querySearchRes(item.data(), 'user') &&
                                    item.id !== user.uid
                            )
                            .map((doc) => doc.data())
                    );
                    setIsLoadingUser(false);
                });
        } else {
            setSearchResUser([]);
            setIsLoadingUser(false);
        }
    }

    function querySearchRes(item, type) {
        if (type === 'event') {
            if (
                item.title
                    .toLowerCase()
                    .includes(searchText.trim().toLowerCase()) ||
                item.description
                    .toLowerCase()
                    .includes(searchText.trim().toLowerCase())
            ) {
                return true;
            } else {
                return false;
            }
        } else if (type === 'user') {
            if (item.displayName) {
                if (
                    item.displayName
                        .toLowerCase()
                        .includes(searchText.trim().toLowerCase()) ||
                    item.inAppUID.includes(searchText.trim().toLowerCase())
                ) {
                    return true;
                } else {
                    return false;
                }
            } else if (
                ('user-' + item.inAppUID).includes(
                    searchText.trim().toLowerCase()
                )
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    function getSearchedEvents() {
        setIsLoadingEvent(true);
        if (searchText.length > 0) {
            db.collection('events')
                .get()
                .then((snapshot) => {
                    setSearchResEvent(
                        snapshot.docs
                            .filter((item) =>
                                querySearchRes(item.data(), 'event')
                            )
                            .map((doc) => doc.data())
                    );
                    setIsLoadingEvent(false);
                });
        } else {
            setSearchResEvent([]);
            setIsLoadingEvent(false);
        }
    }

    function searchTextUpdate(searchedText) {
        setSearchText(searchedText);
    }

    function searchUserAndEvent() {
        getSearchedUser();
        getSearchedEvents();
    }

    return (
        <SafeAreaView style={styles.container} edges={['top', 'left', 'right']}>
            <SearchBar
                placeholder="Suche"
                value={searchText}
                onChangeText={searchTextUpdate}
                onSelectionChange={searchUserAndEvent}
                onSubmitEditing={searchUserAndEvent}
                onClear={() => setSearchResUser([])}
                inputStyle={{
                    color: Colors.backgroundDark,
                }}
                inputContainerStyle={{
                    backgroundColor: 'lightgrey',
                    borderRadius: SPACING.MARGIN / 2,
                }}
                containerStyle={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    borderTopWidth: 0,
                    borderBottomWidth: 0,
                }}
                autoCapitalize="none"
                round
                lightTheme
            />
            <Tab
                value={tabIndex}
                onChange={setTabIndex}
                indicatorStyle={{ backgroundColor: 'grey', height: 3 }}
            >
                <Tab.Item
                    title="User"
                    containerStyle={{
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderBottomWidth: 1,
                        borderBottomColor: 'lightgrey',
                    }}
                    titleStyle={{
                        color: Colors.backgroundDark,
                        fontWeight: '300',
                        fontSize: 16,
                    }}
                />
                <Tab.Item
                    title="Events"
                    containerStyle={{
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderBottomWidth: 1,
                        borderBottomColor: 'lightgrey',
                    }}
                    titleStyle={{
                        color: Colors.backgroundDark,
                        fontSize: 16,
                        fontWeight: '300',
                    }}
                />
            </Tab>
            <TabView value={tabIndex}>
                <TabView.Item style={{ flex: 1 }}>
                    <ScrollView>
                        {isLoadingUser ? (
                            <View style={{ paddingVertical: SPACING.MARGIN }}>
                                <ActivityIndicator size="large" />
                            </View>
                        ) : searchResUser.length > 0 ? (
                            <>
                                {searchResUser.map((item, index) => (
                                    <ListItem key={index} bottomDivider>
                                        <Avatar
                                            icon={{
                                                name: 'person',
                                                color: 'white',
                                            }}
                                            containerStyle={{
                                                backgroundColor: 'grey',
                                            }}
                                            source={{
                                                uri: item.photoURL
                                                    ? item.photoURL
                                                    : null,
                                            }}
                                            size={SPACING.MARGIN * 2}
                                            rounded
                                        />
                                        <ListItem.Content>
                                            <ListItem.Title>
                                                {item.displayName
                                                    ? item.displayName
                                                    : 'user-' +
                                                      item.inAppUID.toUpperCase()}
                                            </ListItem.Title>
                                            <ListItem.Subtitle
                                                numberOfLines={1}
                                                style={{
                                                    fontWeight: '300',
                                                }}
                                            >
                                                {item.status
                                                    ? item.status
                                                    : 'Hi ich bin der neue!'}
                                            </ListItem.Subtitle>
                                        </ListItem.Content>
                                        <Button title="Follow" />
                                    </ListItem>
                                ))}
                            </>
                        ) : searchText === '' ? (
                            <View
                                style={{
                                    justifyContent: 'center',
                                    paddingVertical: SPACING.MARGIN,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon
                                    name="search"
                                    containerStyle={{
                                        paddingHorizontal: SPACING.MARGIN / 2,
                                    }}
                                />
                                <Text style={{ textAlign: 'center' }}>
                                    {msgWOSearch}
                                </Text>
                            </View>
                        ) : (
                            <View
                                style={{
                                    justifyContent: 'center',
                                    paddingVertical: SPACING.MARGIN,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon
                                    name="do-not-disturb"
                                    containerStyle={{
                                        paddingHorizontal: SPACING.MARGIN / 2,
                                    }}
                                />
                                <Text>{msgNoResult}</Text>
                            </View>
                        )}
                    </ScrollView>
                </TabView.Item>
                <TabView.Item style={{ flex: 1 }}>
                    {isLoadingEvent ? (
                        <View style={{ paddingVertical: SPACING.MARGIN }}>
                            <ActivityIndicator size="large" />
                        </View>
                    ) : searchResEvent.length > 0 ? (
                        <>
                            {searchResEvent.map((item, index) => (
                                <ListItem key={index} bottomDivider>
                                    <Avatar
                                        icon={{
                                            name: 'event',
                                            color: 'white',
                                        }}
                                        containerStyle={{
                                            backgroundColor: 'grey',
                                        }}
                                        source={{
                                            uri: item.photoURL
                                                ? item.photoURL
                                                : null,
                                        }}
                                        size={SPACING.MARGIN * 2}
                                        rounded
                                    />
                                    <ListItem.Content>
                                        <ListItem.Title>
                                            {item.title}
                                        </ListItem.Title>
                                        <ListItem.Subtitle
                                            numberOfLines={1}
                                            style={{
                                                fontWeight: '300',
                                            }}
                                        >
                                            {item.description
                                                ? item.description
                                                : '...'}
                                        </ListItem.Subtitle>
                                    </ListItem.Content>
                                    <View style={{}}>
                                        <Text
                                            style={{
                                                paddingBottom: 5,
                                                textAlign: 'right',
                                                fontWeight: '200',
                                            }}
                                        >
                                            (50km)
                                        </Text>
                                        <Button
                                            title="Follow"
                                            type="outline"
                                            titleStyle={{
                                                fontSize: 16,
                                            }}
                                        />
                                    </View>
                                </ListItem>
                            ))}
                        </>
                    ) : searchText === '' ? (
                        <View
                            style={{
                                justifyContent: 'center',
                                paddingVertical: SPACING.MARGIN,
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Icon
                                name="search"
                                containerStyle={{
                                    paddingHorizontal: SPACING.MARGIN / 2,
                                }}
                            />
                            <Text style={{ textAlign: 'center' }}>
                                {msgWOSearch}
                            </Text>
                        </View>
                    ) : (
                        <View
                            style={{
                                justifyContent: 'center',
                                paddingVertical: SPACING.MARGIN,
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Icon
                                name="do-not-disturb"
                                containerStyle={{
                                    paddingHorizontal: SPACING.MARGIN / 2,
                                }}
                            />
                            <Text>{msgNoResult}</Text>
                        </View>
                    )}
                </TabView.Item>
            </TabView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

export default Find;
