import 'react-native-gesture-handler';
import React, { useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Text } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { auth } from '../../firebase';
import { createDrawerNavigator } from '@react-navigation/drawer';
import Settings from './settings';
import { Colors } from '../../assets/colors';
import Profile from './profile';

function ProfileIndex({ navigation }) {
    var currentUser = auth.currentUser;
    const Drawer = createDrawerNavigator();

    return (
        <Drawer.Navigator drawerPosition="right" defaultStatus="open">
            <Drawer.Screen
                name="settings"
                component={Settings}
                options={{ title: 'Einstellungen' }}
            />
            <Drawer.Screen
                name="profile"
                component={Profile}
                options={{ title: 'Profil' }}
            />
        </Drawer.Navigator>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.background,
    },
});

export default ProfileIndex;
