import React from 'react';
import { Alert, ScrollView } from 'react-native';
import { Platform } from 'react-native';
import { StyleSheet, Text } from 'react-native';
import { Button, Icon, ListItem } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../../assets/colors';
import { auth } from '../../firebase';
import { expo } from '../../app.json';
import { useLayoutEffect } from 'react';

function Settings({ navigation }) {
    function logOut() {
        if (Platform.OS !== 'web') {
            Alert.alert(
                'Abmelden',
                'Willst du dich wirklich abmelden?',
                [
                    { text: 'Nein', style: 'cancel' },
                    {
                        text: 'Abmelden',
                        style: 'destructive',
                        onPress: function () {
                            const user = auth.currentUser;
                            const isAnonymous = user.isAnonymous;

                            auth.signOut();
                            if (isAnonymous) {
                                user.delete();
                            }
                        },
                    },
                ],
                { cancelable: true }
            );
        } else {
            const user = auth.currentUser;
            const isAnonymous = user.isAnonymous;

            auth.signOut();
            if (isAnonymous) {
                user.delete();
            }
        }
    }

    return (
        <SafeAreaView style={styles.container}>
            <ScrollView>
                <ListItem>
                    <Icon name="settings" />
                    <ListItem.Content>
                        <ListItem.Title>Settings</ListItem.Title>
                    </ListItem.Content>
                    <ListItem.Chevron
                        name="chevron-right"
                        type="material"
                        size={SPACING.MARGIN}
                    />
                </ListItem>
                <Button
                    type="clear"
                    title="Abmelden"
                    icon={{
                        name: 'power-settings-new',
                        color: 'red',
                    }}
                    titleStyle={{
                        color: 'red',
                    }}
                    containerStyle={{
                        marginTop: SPACING.MARGIN,
                        marginBottom: SPACING.MARGIN / 2,
                    }}
                    onPress={logOut}
                />
                <Text
                    style={{
                        width: '100%',
                        fontWeight: '100',
                        textAlign: 'center',
                    }}
                >
                    {expo.version}
                </Text>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

export default Settings;
