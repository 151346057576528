import 'react-native-gesture-handler';
import React from 'react';
import { StatusBar, StyleSheet, Text, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { Colors, SPACING } from './assets/colors';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Loading from './screens/loading';
import LoginList from './screens/loginList';
import LoginUser from './screens/loginUser';
import RegisterUser from './screens/registerUser';
import HomeIndex from './screens/protected/homeIndex';

export default function App() {
    const Stack = createStackNavigator();

    return (
        <SafeAreaProvider>
            <NavigationContainer style={styles.container}>
                <StatusBar
                    backgroundColor={Colors.primaryDark}
                    barStyle="dark-content"
                />
                <Stack.Navigator
                    style={styles.container}
                    initialRouteName={'loading'}
                >
                    <Stack.Screen name={'loading'} component={Loading} />
                    <Stack.Screen name={'loginList'} component={LoginList} />
                    <Stack.Screen name={'loginUser'} component={LoginUser} />
                    <Stack.Screen
                        name={'registerUser'}
                        component={RegisterUser}
                    />

                    {/** Protected Pages */}
                    <Stack.Screen name={'homeIndex'} component={HomeIndex} />
                </Stack.Navigator>
            </NavigationContainer>
        </SafeAreaProvider>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.background,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
