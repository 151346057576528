import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import { Button, Divider, Icon, Input, Text } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, maxWidth, SPACING } from '../assets/colors';
import { auth } from '../firebase';

function LoginUser({ navigation }) {
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Anmelden',
            headerShown: false,
        });
    }, []);

    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const emailFieldRef = useRef();
    const passwordFieldRef = useRef();

    function validateEmail() {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(userEmail);
    }

    function loginUser() {
        setEmailError('');
        setPasswordError('');

        var emailCorrect = false;
        var passwordCorrect = false;

        if (userEmail.trim() !== '') {
            if (validateEmail()) {
                emailCorrect = true;
            } else {
                setEmailError('Dies ist keine gültige E-Mail Adresse!');
            }
        } else {
            setUserEmail('');
            setEmailError('Dieses Feld darf nicht leer sein!');
        }

        if (userPassword.trim() !== '') {
            passwordCorrect = true;
        } else {
            setUserPassword('');
            setPasswordError('Dieses Feld darf nicht leer sein!');
        }

        if (emailCorrect && passwordCorrect) {
            auth.signInWithEmailAndPassword(userEmail, userPassword).then(() =>
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'homeIndex' }],
                })
            );
        }
    }

    return (
        <SafeAreaView
            style={{
                flex: 1,
                backgroundColor: Colors.backgroundDark,
                justifyContent: 'center',
                alignItems: 'center',
            }}
            edges={['left', 'right']}
        >
            <Text style={styles.title}>Login</Text>
            <Input
                containerStyle={styles.containerInput}
                inputContainerStyle={styles.inputContainer}
                leftIcon={{
                    name: 'email',
                    color: Colors.backgroundDark,
                    size: SPACING.MARGIN,
                }}
                placeholder="ihre@email.de"
                value={userEmail}
                onChangeText={(event) => {
                    setUserEmail(event);
                    setEmailError('');
                }}
                autoCapitalize="none"
                ref={emailFieldRef}
                //onSubmitEditing={passwordFieldRef.current.focus()}
                autoFocus
                errorStyle={{ paddingLeft: SPACING.MARGIN }}
                errorMessage={emailError}
            />
            <Input
                containerStyle={styles.containerInput}
                inputContainerStyle={styles.inputContainer}
                leftIcon={{
                    name: 'lock',
                    color: Colors.backgroundDark,
                    size: SPACING.MARGIN,
                }}
                rightIcon={
                    showPassword ? (
                        <Icon
                            name="visibility-off"
                            color={Colors.backgroundDark}
                            onPress={() => setShowPassword(false)}
                        />
                    ) : (
                        <Icon
                            name="visibility"
                            color={Colors.backgroundDark}
                            onPress={() => setShowPassword(true)}
                        />
                    )
                }
                placeholder="Passwort"
                value={userPassword}
                onChangeText={(event) => {
                    setUserPassword(event);
                    setPasswordError('');
                }}
                autoCapitalize="none"
                ref={passwordFieldRef}
                secureTextEntry={!showPassword}
                errorStyle={{ paddingLeft: SPACING.MARGIN }}
                errorMessage={passwordError}
                onSubmitEditing={loginUser}
            />
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '80%',
                    maxWidth: maxWidth,
                }}
            >
                <Button
                    icon={{ name: 'arrow-back', color: 'lightgrey' }}
                    titleStyle={{ color: 'lightgrey' }}
                    type="clear"
                    title="Zurück"
                    onPress={() => navigation.goBack()}
                />
                <Button title="Anmelden" onPress={loginUser} />
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    title: {
        width: '80%',
        maxWidth: maxWidth,
        fontWeight: '900',
        color: Colors.background,
        fontSize: SPACING.MARGIN * 1.5,
        marginBottom: SPACING.MARGIN * 1.5,
    },
    containerInput: {
        width: '80%',
        maxWidth: maxWidth,
        paddingRight: 0,
        paddingLeft: 0,
    },
    inputContainer: {
        backgroundColor: Colors.background,
        borderBottomWidth: 0,
        paddingHorizontal: SPACING.MARGIN,
        paddingVertical: SPACING.MARGIN / 2,
        borderRadius: SPACING.MARGIN,
    },
});

export default LoginUser;
