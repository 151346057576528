export const Colors = {
    primary: '#92DCE5',
    primarydark: '#8FC5C8',
    accent: '#96E6B3',
    accentDark: '#7BD389',
    backgroundDark: '#383D3B',
    background: '#EEE5E9',
};
export const SPACING = { MARGIN: 25, USER_IMAGE: 75 };
export const maxWidth = 500;
