import React, { useLayoutEffect } from 'react';
import { Text, View } from 'react-native';
import { TouchableHighlight } from 'react-native';
import { Icon } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../../assets/colors';

function Profile({ navigation }) {
    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => <></>,
            headerRight: () => (
                <View>
                    <Icon
                        name="menu"
                        color={Colors.backgroundDark}
                        containerStyle={{ marginHorizontal: SPACING.MARGIN }}
                        onPress={() => navigation.openDrawer()}
                    />
                </View>
            ),
        });
    }, []);

    return (
        <SafeAreaView
            style={{ flex: 1, backgroundColor: 'whitesmoke' }}
            edges={['top', 'left', 'right']}
        ></SafeAreaView>
    );
}

export default Profile;
