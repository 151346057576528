import React from 'react';
import { useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Button, Divider, Text } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, maxWidth, SPACING } from '../assets/colors';
import { auth } from '../firebase';

function LoginList({ navigation }) {
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Login',
            headerShown: false,
        });
    }, []);
    return (
        <SafeAreaView
            style={{
                flex: 1,
                backgroundColor: Colors.backgroundDark,
                justifyContent: 'center',
                alignItems: 'center',
            }}
            edges={['left', 'right']}
        >
            <Text style={styles.title}>Login</Text>
            <Button
                icon={{
                    name: 'email',
                    color: Colors.background,
                    size: SPACING.MARGIN * 1.5,
                }}
                title="Mit E-Mail einloggen"
                containerStyle={styles.buttonContainer}
                buttonStyle={[
                    styles.button,
                    { backgroundColor: Colors.accentDark },
                ]}
                onPress={() => navigation.navigate('loginUser')}
            />
            <Button
                icon={{
                    name: 'email',
                    color: Colors.background,
                    size: SPACING.MARGIN * 1.5,
                }}
                title="Mit E-Mail registrieren"
                containerStyle={styles.buttonContainer}
                buttonStyle={[
                    styles.button,
                    { backgroundColor: Colors.primarydark },
                ]}
                onPress={() => navigation.navigate('registerUser')}
            />
            <Divider
                style={{
                    width: '60%',
                    maxWidth: maxWidth,
                    marginVertical: SPACING.MARGIN,
                }}
                orientation="horizontal"
                color={Colors.background}
            />
            <Button
                icon={{
                    name: 'perm-identity',
                    color: Colors.background,
                    size: SPACING.MARGIN * 1.5,
                }}
                title="Als Gast fortfahren"
                titleStyle={{ color: Colors.background }}
                containerStyle={styles.buttonContainer}
                buttonStyle={styles.button}
                type="clear"
                onPress={() =>
                    auth.signInAnonymously().then(() =>
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'homeIndex' }],
                        })
                    )
                }
            />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    title: {
        width: '80%',
        maxWidth: maxWidth,
        fontWeight: '900',
        color: Colors.background,
        fontSize: SPACING.MARGIN * 2,
        marginBottom: SPACING.MARGIN * 1.5,
    },
    buttonContainer: {
        width: '80%',
        maxWidth: maxWidth,
        marginBottom: SPACING.MARGIN / 2,
    },
    button: {
        borderRadius: SPACING.MARGIN,
        paddingVertical: SPACING.MARGIN / 2,
    },
});

export default LoginList;
