import React from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import { Icon } from 'react-native-elements';
import { Colors, SPACING } from '../../assets/colors';

export default function CustomTabBar({ state, descriptors, navigation }) {
    return (
        <View
            style={{
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                paddingTop: SPACING.MARGIN / 2,
                paddingBottom:
                    Platform.OS === 'web' ? SPACING.MARGIN / 2 : SPACING.MARGIN,
                alignItems: 'center',
                backgroundColor: Colors.backgroundDark,
            }}
        >
            {state.routes.map((route, index) => {
                const { options } = descriptors[route.key];
                const label =
                    options.tabBarLabel !== undefined
                        ? options.tabBarLabel
                        : options.title !== undefined
                        ? options.title
                        : route.name;

                let iconName;

                if (route.name === 'home') {
                    iconName = 'home';
                } else if (route.name === 'events') {
                    iconName = 'event';
                } else if (route.name === 'find') {
                    iconName = 'search';
                } else if (route.name === 'profile') {
                    iconName = 'person';
                }

                const isFocused = state.index === index;

                const onPress = () => {
                    const event = navigation.emit({
                        type: 'tabPress',
                        target: route.key,
                        canPreventDefault: true,
                    });

                    if (!isFocused && !event.defaultPrevented) {
                        // The `merge: true` option makes sure that the params inside the tab screen are preserved
                        navigation.navigate({ name: route.name, merge: true });
                    }
                };

                const onLongPress = () => {
                    navigation.emit({
                        type: 'tabLongPress',
                        target: route.key,
                    });
                };

                return (
                    <TouchableOpacity
                        accessibilityRole="button"
                        accessibilityState={isFocused ? { selected: true } : {}}
                        accessibilityLabel={options.tabBarAccessibilityLabel}
                        testID={options.tabBarTestID}
                        onPress={onPress}
                        onLongPress={onLongPress}
                        style={{}}
                        key={index}
                    >
                        <Icon
                            name={iconName}
                            size={Platform.OS === 'web' ? 36 : 32}
                            color={isFocused ? Colors.accentDark : '#999'}
                        />
                        {/*isFocused ? (
                            <></>
                        ) : (
                            <Text
                                style={{
                                    color: isFocused ? '#673ab7' : '#222',
                                }}
                            >
                                {label}
                            </Text>
                            )*/}
                    </TouchableOpacity>
                );
            })}
        </View>
    );
}
