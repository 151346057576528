import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyDvD3Z5a-bq5nOgsMlqy4HLnrnZWp-IxVc',
    authDomain: 'somot-de-dev.firebaseapp.com',
    projectId: 'somot-de-dev',
    storageBucket: 'somot-de-dev.appspot.com',
    messagingSenderId: '76855190075',
    appId: '1:76855190075:web:f9406ec12ed3b1daae721e',
};

let app;

if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig);
} else {
    app = firebase.app();
}

const db = app.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage, firebaseConfig };
